<template id="">
  <div id="wall">
    <div style="padding: 5em 50px 20px">
      <h3 class="uk-text-background">分析結果PDF一覧</h3>
      <hr />
    </div>
    <div v-cloak>
      <h3>ストレスチェックデータ(集団)</h3>
      <table>
        <thead>
          <tr class="fixed01">
            <th v-if="flg == true" class="large">帳票の選択</th>
            <template v-else>
              <td>PDFの選択</td>
              <td>状態</td>
            </template>
          </tr>
        </thead>
        <tbody>
          <template v-if="flg == true">
            <tr v-for="f_name in lists" :key="f_name">
              <td @click="show_pdf_list(f_name)">
                <span>{{ f_name }}</span>
              </td>
            </tr>
            <tr>
              <td class="bd">
                <button
                  type="button"
                  name="button"
                  @click="$router.back()"
                  class="uk-button uk-button-default uk-width-1-3"
                >
                  &lt;&nbsp;戻る
                </button>
              </td>
            </tr>
          </template>
          <template v-else-if="flg == false">
            <tr
              v-for="(ff_name, index) in pdf_folder_list"
              :key="ff_name"
              @click="get_pdf_detail(ff_name, index)"
            >
              <td class="w50 bd">
                <span>{{ ff_name }}</span>
              </td>
              <td class="w50 bd">
                <span>{{ returnStatus(index) }}</span>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <button
                  type="button"
                  name="button"
                  @click="flg = true"
                  class="uk-button uk-button-default uk-width-1-3"
                >
                  &lt;&nbsp;戻る
                </button>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  data() {
    return {
      sc_id: null,
      detail: null,
      targets_list: null,
      status: null,
      ank_num: 0,
      pdf_url: null,
      tmp: null,
      do_something: true,
      pdfs: [],
      lists: [],
      flg: true,
      pdf_folder_list: [],
      tmp1: null,
      blobs: null,
      change_flg: false,
      comment: "",
      pdf_status: null,
      multiple: null,
      pdf_name: null,
      p_flg: true,
      pdf_blobs: [],
      pdf_wp_name_list: null,
      index: null,
    };
  },
  mounted() {
    this.$store.state.common.sIsLoading = false;
    document.title = "分析結果一覧";
  },
  computed: {
    ret_status: function () {
      switch (this.detail.sc_status) {
        case 0:
          return "依頼未確定";
        case 1:
          return "実施期間待ち";
        case 2:
          return "要：マークシート印刷";
        case 3:
          return "ストレスチェック実施中";
        case 4:
          return "ストレスチェック実施期間終了";
        case 5:
          return "要：OCR処理";
        case 6:
          return "分析処理待ち";
        case 7:
          return "要：ストレスチェック結果印刷";
        case 8:
          return "ストレスチェック完了";
        case 10:
          return "依頼取り下げ";
      }
      return "不明";
    },
    returnStatus: function () {
      return function (num) {
        let str = "";
        console.log(this.pdf_status[num]);
        if (this.pdf_status[num] != null) {
          if (this.pdf_status[num] == -1) {
            str = "コメント自動挿入エラー";
          } else if (this.pdf_status[num] == 0) {
            str = "";
          } else if (this.pdf_status[num] == 1) {
            str = "コメント自動挿入";
          } else if (this.pdf_status[num] == 2) {
            str = "コメント手動入力";
          }
        }
        return str;
      };
    },
  },
  created() {
    this.$store.state.common.sIsLoading = true;
    this.$store.state.common.sDataLoading = true;
    this.sc_id = this.$store.state.sc_id;
    this.get_list(this.sc_id);
    this.$store.state.common.sIsLoading = false;
    this.$store.state.common.sDataLoading = false;
  },
  methods: {
    get_pdf_detail(file_path, index) {
      this.pdf_name = file_path;
      this.index = index;
      this.$axios
        .post("/client/get_pdf_detail", {
          alias_id: this.$store.state.alias_id,
          sc_id: this.sc_id,
          folder_class: this.tmp1,
          folder_name: file_path,
        })
        .then((response) => {
          if (response.data.flg) {
            this.pdfs = [];
            // this.pdf_url = "data:application/pdf;base64,"+response.data
            for (let pdf of response.data.pdf) {
              this.pdfs.push(window.URL.createObjectURL(this.make_blob(pdf)));
            }
            console.log(this.pdfs);
            if (this.pdfs.length > 1) {
              this.pdf_url = this.pdfs[1];
            } else {
              this.pdf_url = this.pdfs[0];
            }
            this.multiple = response.data.multiple;
            window.open(this.pdf_url);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async show_pdf_list(f_name) {
      //一つの職場か複数の職場どちらかの中身を取得
      this.tmp1 = f_name;
      this.pdf_url = null;
      await this.$axios
        .post("/client/show_pdf_list", {
          alias_id: this.$store.state.alias_id,
          sc_id: this.sc_id,
          folder_name: f_name,
        })
        .then((response) => {
          console.log(response);
          if (response.data.flg) {
            this.pdf_folder_list = response.data.folder_list;
            this.flg = false;
            this.pdf_status = response.data.pdf_status;
            this.pdf_wp_name_list = response.data.pdf_wp_name_list;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    get_list(sc_id) {
      // 一つの職場か複数の職場かを取得
      this.$axios
        .post("/client/get_folder_list", {
          alias_id: this.$store.state.alias_id,
          sc_id: sc_id,
        })
        .then((response) => {
          if (response.data.flg) {
            this.tmp = sc_id;
            this.lists = response.data.folder_list;
            console.log(this.lists);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    change_pdf(num) {
      this.pdf_url = this.pdfs[num];
      console.log(this.pdf_url);
    },
    async show_result_pdf(emp_code) {
      if (!this.do_something) return;
      this.do_something = false;
      this.tmp = emp_code;
      console.log(emp_code);
      await this.$axios
        .post("/client/get_personal_pdf", {
          alias_id: this.$store.state.alias_id,
          sc_id: this.sc_id,
          emp_code: emp_code,
        })
        .then((response) => {
          console.log("A");
          console.log(response);
          // let blob_res = new Blob([response.data], {type:"application/pdf"});
          // this.pdf_url = window.URL.createObjectURL(blob_res)
          if (response.data.flg) {
            this.pdfs = [];
            // this.pdf_url = "data:application/pdf;base64,"+response.data
            let data_uri_pdf = response.data.pdf;
            // let data_uri_pdf2 = response.data.pdf2
            // data_uri_pdf1 = encodeURIComponent(data_uri_pdf1)
            // data_uri_pdf2 = encodeURIComponent(data_uri_pdf2)
            // this.pdfs.push(this.createBlob(data_uri_pdf1))
            // this.pdfs.push(this.createBlob(data_uri_pdf2))

            // this.pdfs.push("data:application/pdf;base64," + data_uri_pdf1)
            // this.pdfs.push("data:application/pdf;base64," + data_uri_pdf2)
            this.pdf_url = this.make_blob(data_uri_pdf);
            // console.log(this.pdfs)
            // this.pdf_url = this.pdfs[0]
            window.open(window.URL.createObjectURL(this.pdf_url));
          } else {
            this.pdf_url = null;
          }
          this.do_something = true;
        })
        .catch((error) => {
          console.log("B");
          console.log(error);
          this.do_something = true;
        });
      this.do_something = true;
      // window.location.replace(this.$route.path + "#modal1")
    },
    createBlob(uri) {
      let mtype = "data:application/pdf;base64,";
      // let byteString = atob( uri.split( "," )[1] ) ;
      // let mimeType = uri.match( /(:)([a-z\\/]+)(;)/ )[2] ;
      let byteString = uri;
      let mimeType = mtype;
      let content;
      for (
        let i = 0, l = byteString.length, content = new Uint8Array(l);
        l > i;
        i++
      ) {
        content[i] = byteString.charCodeAt(i);
      }

      let blob = new Blob([content], {
        type: mimeType,
      });

      let a = window.URL.createObjectURL(blob);
      console.log(a);
      return a;
    },
    make_blob(base64_str) {
      let dataURI = "data:application/pdf;base64," + base64_str;
      // "iVBORw..."をバイナリに変換
      var byteString = atob(dataURI.split(",")[1]);

      // "image/png"
      var mimeType = dataURI.match(/(:)([a-z/]+)(;)/)[2];

      // バイナリからBlobを作成
      for (
        var i = 0, l = byteString.length, content = new Uint8Array(l);
        l > i;
        i++
      ) {
        content[i] = byteString.charCodeAt(i);
      }

      var blob = new Blob([content], {
        type: mimeType,
      });
      return blob;
      // window.open(window.URL.createObjectURL( blob )) ;
    },
  },
};
</script>

<style scoped>
.overlays {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  transition: opacity 200ms;
  visibility: hidden;
  opacity: 0;
}

.overlays .cancel {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  cursor: default;
}

.overlays:target {
  visibility: visible;
  opacity: 1;
}

.modals {
  margin: 0px auto;
  padding: 20px;
  background: #fff;
  border: 1px solid #666;
  width: 80%;
  height: 100%;
  border-radius: 6px;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
  position: relative;
}

.modals h2 {
  margin-top: 0;
}

.modals .close {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 20px;
  right: 20px;
  opacity: 0.8;
  transition: all 200ms;
  font-size: 24px;
  font-weight: bold;
  text-decoration: none;
  color: #777;
}

.modals .close:hover {
  opacity: 1;
}

.modals .cont {
  overflow-y: auto;
  max-height: 80vh;
}

.modals p {
  margin: 0 0 1em;
  text-align: center;
}

.modals p:last-child {
  margin: 0;
}

a.button:hover {
  box-shadow: 0 12px 23px rgba(0, 0, 0, 0.23), 0 10px 10px rgba(0, 0, 0, 0.19);
}

[v-cloak] {
  display: none;
  cursor: wait;
}
</style>
